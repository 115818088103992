<template>
    <div class="con-wrap">
        <CarrotTitle title="Media Zone">
            - 첨부파일은 각각 최대 2MB까지 파일을 올리실 수 있습니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <!-- <tr>
                                <th>상단고정</th>
                                <td>
                                    <label><input type="checkbox" name="topfix" v-model="bbs.topfix"><span class="ml-5"> 사용</span></label>
                                </td>
                            </tr> -->
                            <tr>
                                <th><span class="txt-red">*</span> 카테고리</th>
                                <td>
                                    <select class="w-150px float-left" v-model="bbs.ca_name">
                                        <option value="">카테고리 전체</option>
                                        <option :value="irow" v-for="(irow, i) in bbs.ca_list" :key="i">{{ irow }}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 제목</th>
                                <td>
                                    <input type="text" v-model="bbs.title" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 내용</th>
                                <td>
                                    <!-- <textarea v-model="bbs.content" class="w-100per h-300px"></textarea> -->
                                    <CarrotEditor etype="mediazone" v-model="bbs.content" class="w-100per h-400px"></CarrotEditor>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 대표이미지</th>
                                <td>
                                    <CarrotFileUploader ref="file1"></CarrotFileUploader>
                                </td>
                            </tr>
                            <tr>
                                <th>파일첨부</th>
                                <td>
                                    <CarrotFileUploader ref="file2"></CarrotFileUploader>
                                    <CarrotFileUploader ref="file3" class="mt-5"></CarrotFileUploader>
                                    <CarrotFileUploader ref="file4" class="mt-5"></CarrotFileUploader>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn-default float-left mt-30" @click="bbs.showList">목록</button>
                    <button class="btn-default float-right mt-30" @click="bbs.onSave">새글 작성하기</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotEditor from '@/components/common/CarrotEditor.vue'


export default {
    layout:"CARROTZone",
    components: {
        CarrotEditor
    },
    setup() {
        const router = useRouter();
        const toast = useToast();

        const file1 = ref(null);
        const file2 = ref(null);
        const file3 = ref(null);
        const file4 = ref(null);
        const bbs = reactive({
            ca_list : [],
            ca_name : "",
            topfix  : false,
            title   : "",
            content : "",

            showList: () => {
                router.push({ name:"CARROTZone-MediaZoneList" });
            },

            getCategory: () => {
                let params = { };
                axios.get('/rest/carrotzone/mediazoneCategory', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.ca_list = res.data.list;
                    } else {
                        console.info(res.data.err_msg);
                    }
                })
            },

            onSave: () => {
                if( !bbs.ca_name ){
                    toast.error("카테고리를 선택하세요.");
                    return;
                }
                if( !bbs.title ){
                    toast.error("제목을 입력하세요.");
                    return;
                }
                if( !bbs.content ){
                    toast.error("내용을 입력하세요.");
                    return;
                }
                if(file1.value.file.filedata===null) {
                    toast.error("대표이미지를 입력하세요.");
                    return;
                }

                var formData = new FormData();
                formData.append("ca_name", bbs.ca_name);
                formData.append("topfix", bbs.topfix==true?"Y":"N");
                formData.append("title", bbs.title);
                formData.append("content", bbs.content);
                if(file1.value.file.filedata!==null) {
                    formData.append("nfile[]", file1.value.file.filedata);
                }
                if(file2.value.file.filedata!==null) {
                    formData.append("nfile[]", file2.value.file.filedata);
                }
                if(file3.value.file.filedata!==null) {
                    formData.append("nfile[]", file3.value.file.filedata);
                }
                if(file4.value.file.filedata!==null) {
                    formData.append("nfile[]", file4.value.file.filedata);
                }
                axios.post('/rest/carrotzone/mediazoneAdd', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{
                    if( res.data.err == 0 ){
                        router.push({ 
                            name:"CARROTZone-MediaZoneView-idx",
                            params : { idx:res.data.idx }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            }
        });
        
        onMounted(() => {
            // Mounted
            bbs.getCategory();
        });

        onUnmounted(() => { 
            // UnMounted
        });
        return {bbs, file1, file2, file3, file4};
    }
}
</script>

<style lang="scss" scoped>
</style>